import { createAsyncThunk } from '@reduxjs/toolkit'
import { ClubMembershipListApi } from './ClubMembership.services'
import { paginationData } from '../Pagination/reducers'

//services
export const ClubMembershipList = createAsyncThunk(
    'Club/Membership/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClubMembershipListApi(data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
