import { createAsyncThunk } from '@reduxjs/toolkit'
import { countryListApi } from './country.services'

//
export const countryList = createAsyncThunk('Country/List', async (data, { rejectWithValue }) => {
    try {
        const res = await countryListApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
