import { combineReducers } from 'redux'
import Login from './Auth/reducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
import Modal from './Modal/reducer'
import Pagination from './Pagination/reducers'
import Plans from './Plans/reducer'
import SubUser from './SubUser/reducer'
import Country from './Country/reducer'
import State from './State/reducer'
import ClubMemberships from './ClubMemberships/reducer'
import ClubPlans from './ClubPlans/reducer'
import ClubShoppingCart from './ClubShoppingCart/reducer'
import ProcessingFees from './ProcessingFees/reducer'
import PurchasedMembership from './PurchasedMembership/reducer'
import ClubSanction from './ClubSanction/reducer'
import ClubSanctionPlans from './ClubSanctionPlans/reducer'
import ClubSanctionShoppingCart from './ClubSanctionShoppingCart/reducer'
import PurchasedSanction from './PurchasedSanction/reducer'
import NewsLetter from './NewsLetter/reducer'

const rootReducer = combineReducers({
    Login,
    loadingBar: loadingBarReducer,

    // A-Z
    Country,
    ClubMemberships,
    ClubPlans,
    ClubSanctionPlans,
    ClubSanctionShoppingCart,
    ClubSanction,
    ClubShoppingCart,
    Modal,
    NewsLetter,
    Pagination,
    Plans,
    ProcessingFees,
    PurchasedMembership,
    PurchasedSanction,
    State,
    SubUser
})

export default rootReducer
