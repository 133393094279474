import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    ClubSanctionPaymentIntentApi,
    ClubSanctionPaymentSuccessApi
} from './ClubSanctionShoppingCart.services'

//services

export const ClubSanctionPaymentIntent = createAsyncThunk(
    '/Club/Sanction/payment/intent/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubSanctionPaymentIntentApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const ClubSanctionPaymentCompleted = createAsyncThunk(
    '/Club/Sanction/payment/intent/success',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubSanctionPaymentSuccessApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
