import { createAsyncThunk } from '@reduxjs/toolkit'
import { stateListApi } from './state.services'

//
export const stateList = createAsyncThunk('State/List', async (data, { rejectWithValue }) => {
    try {
        const res = await stateListApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
