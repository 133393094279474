import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MemberEmailAlreadyExitApi,
    UserRegisterApi,
    clubForgotPasswordApi,
    clubRegisterApi,
    emailAlreadyExitApi,
    memberEmailResentApi,
    memberForgotPasswordApi,
    memberVerifyTokenApi,
    sponsorVendorProfileUpdateApi,
    userChangePasswordApi,
    userEmailResentApi,
    userForgotPasswordApi,
    userLoadApi,
    userLoginApi,
    userLogoutApi,
    userProfileUpdateApi,
    verifyTokenApi
} from './Auth.services'

export const userLogin = createAsyncThunk('user/login', async (data, { rejectWithValue }) => {
    try {
        const res = await userLoginApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const memberLogin = createAsyncThunk('member/login', async (data, { rejectWithValue }) => {
    try {
        const res = await userLoginApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const userLoad = createAsyncThunk('user/load', async (data, { rejectWithValue }) => {
    try {
        const res = await userLoadApi()

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const userLogout = createAsyncThunk('user/logout', async (data, { rejectWithValue }) => {
    try {
        const res = await userLogoutApi(data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const userProfileUpdate = createAsyncThunk(
    'user/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userProfileUpdateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const sponsorVendorProfileUpdate = createAsyncThunk(
    'user/sponsorVendor-profile-update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await sponsorVendorProfileUpdateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const userChangePassword = createAsyncThunk(
    'user/changePassword',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userChangePasswordApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const userForgotPassword = createAsyncThunk(
    'user/forgot-password',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userForgotPasswordApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const clubRegister = createAsyncThunk('club/register', async (data, { rejectWithValue }) => {
    try {
        const res = await clubRegisterApi(data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const UserRegister = createAsyncThunk(
    '/user/register',
    async (data, { rejectWithValue }) => {
        try {
            const res = await UserRegisterApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const verifyToken = createAsyncThunk(
    'club/verifyToken',
    async (data, { rejectWithValue }) => {
        try {
            const res = await verifyTokenApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const memberVerifyToken = createAsyncThunk(
    '/member/verifyToken',
    async (data, { rejectWithValue }) => {
        try {
            const res = await memberVerifyTokenApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const emailAlreadyExit = createAsyncThunk(
    'club/alreadyEmail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await emailAlreadyExitApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MemberEmailAlreadyExit = createAsyncThunk(
    'Member/alreadyEmail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberEmailAlreadyExitApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const userEmailResent = createAsyncThunk(
    'club/resentEmail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userEmailResentApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const memberEmailResent = createAsyncThunk(
    'member/resentEmail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await memberEmailResentApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const memberForgotPassword = createAsyncThunk(
    'member/resentEmail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await memberForgotPasswordApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const clubForgotPassword = createAsyncThunk(
    'club/resentEmail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await clubForgotPasswordApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
