import { createAsyncThunk } from '@reduxjs/toolkit'
import { paginationData } from '../Pagination/reducers'
import {
    SubUserListApi,
    SubUserCreateApi,
    SubUserDeleteApi,
    SubUserUpdateApi,
    SubUserByIdApi,
    MemberUserListApi
} from './SubUser.services'

//services
export const SubUserList = createAsyncThunk(
    '/subuser/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SubUserListApi(data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SubUserById = createAsyncThunk(
    '/subuser/getById',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SubUserByIdApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MemberUserList = createAsyncThunk(
    '/member/user/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberUserListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SubUserDelete = createAsyncThunk(
    '/subuser/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SubUserDeleteApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SubUserCreate = createAsyncThunk(
    'MembershipPlan/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SubUserCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SubUserUpdate = createAsyncThunk(
    '/MembershipPlan/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SubUserUpdateApi(data.id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
