// Admin
export const USER_LOGIN_URL = '/login'
export const USER_LOAD_URL = '/user-member/profile/profile_details'
export const USER_LOGOUT_URL = '/logout'
export const USER_PROFILE_UPDATE_URL = '/user-member/profile'
export const SPONSOR_VENDOR_PROFILE_UPDATE_URL = '/profile'
export const USER_PASSWORD_CHANGE_URL = '/user-member/password/change_password'
export const USER_FORGOT_PASSWORD_URL = '/password/forgot_password'
export const CLUB_REGISTER_URL = '/clubs/account/registration'
export const CLUB_EMAIL_ALREADY_EXISTS_URL = '/clubs/account/is-email-exist-club'
export const CLUB_VERIFY_EMAIL_URL = '/clubs/account/club-activation'
export const CLUB_RESEND_VERIFY_EMAIL_URL = '/clubs/account/resend-verify-link-club'

//
export const USER_REGISTER_URL = '/user-member/register'
export const MEMBER_RESEND_VERIFY_EMAIL_URL = '/user-member/user_activation/resend_verify_link'
export const MEMBER_EMAIL_ALREADY_EXISTS_URL = '/user-member/register/is_email_exist'
export const MEMBER_VERIFY_EMAIL_URL = '/user-member/user_activation'
export const MEMBER_LOGOUT = '/logout'
export const MEMBER_FORGOT_PASSWORD_URL = '/user-member/password/forgot_password'

export const CLUB_FORGOT_PASSWORD_URL = '/clubs/account-setting/forgot_password'

// Club
export const PLANS_LIST_URL = '/clubs/membership-fee-config/membership-plan'

// Country
export const COUNTRY_LIST_URL = '/address/country/all'

// State
export const STATE_LIST_URL = '/address/state/all'

// SubUser List
export const SUB_USER_LIST = '/user-member/subuser'
export const SUB_USER_CREATE = '/user-member/subuser'
export const SUB_USER_ONE = '/user-member/subuser/one'
export const SUB_USER_UPDATE = '/user-member/subuser'
export const SUB_USER_DELETE = '/user-member/subuser'
export const MEMBER_USER_LIST = '/clubs/user-member/subuser/club-membership/member-subuser-list'

export const CLUB_MEMBERSHIP_LIST = '/clubs/account/all-clubs'
export const CLUB_PLAN_LIST = '/clubs/membership-plans/club/membership-plan-by-club'
export const PLAN_DETAIL = '/clubs/membership-plans/club-membership/club/one'

export const CLUB_PAYMENT_INTENT_CREATE = '/user/club-membership-order/create-payment-intent'
export const CLUB_PAYMENT_SUCCESS = '/user/club-membership-order/process-order'
export const MEMBERSHIP_ALL_STATUS = '/user/club-membership-order/count-status'
export const CLUB_ID_CONFIGURATION = '/clubs/membership-id-configuration/club/all'

export const CLUB_MEMBER_PROCESSING_FEES = '/clubs/membership-processing-fee/club/all'
export const ADMIN_MEMBER_PROCESSING_FEES = '/clubs/membership/fee-config/all'

// Purchased Membership List
export const CLUB_PURCHASED_MEMBERSHIP_LIST = '/user/club-membership-order/membership-order'

// Sanction
export const CLUB_SANCTION_LIST = '/clubs/account/all-clubs-sanction'
export const CLUB_PLAN_SANCTION_LIST = '/clubs/sanction-plan/club/sanction-plan-by-club'
export const PLAN_SANCTION_DETAIL = 'clubs/sanction-plan/club/one'

export const CLUB_SANCTION_PAYMENT_INTENT_CREATE = '/user/sanction-order/create-payment-intent'
export const CLUB_SANCTION_PAYMENT_SUCCESS = '/user/sanction-order/process-order'
export const SANCTION_PROCESSING_FEES_LIST_URL = '/clubs/sanction/fee-config/all'
export const CLUB_SANCTION_PROCESSING_FEES = '/clubs/sanction-processing-fee/club/all'

export const CLUB_PURCHASED_SANCTION_LIST = '/user/sanction-order/sanction-order'

export const NEWS_LETTER_LIST = '/newsletters/all'
