import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    AdminMemberProcessFeeListApi,
    AdminSanctionProcessFeeListApi,
    ClubMemberProcessFeeListApi,
    ClubSanctionProcessFeeListApi
} from './ProcessingFees.services'

export const ClubMemberProcessFeeList = createAsyncThunk(
    'club/member/processing/fees',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubMemberProcessFeeListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const ClubSanctionProcessFeeList = createAsyncThunk(
    'club/sanction/processing/fees',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubSanctionProcessFeeListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const AdminMemberProcessFeeList = createAsyncThunk(
    'admin/member/processing/fees',
    async (data, { rejectWithValue }) => {
        try {
            const res = await AdminMemberProcessFeeListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const AdminSanctionProcessFeeList = createAsyncThunk(
    'admin/sanction/processing/fees',
    async (data, { rejectWithValue }) => {
        try {
            const res = await AdminSanctionProcessFeeListApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
