import { get, patch, post } from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const userLoginApi = (data) => post(URL.USER_LOGIN_URL, data)
export const userLoadApi = (data) => get(URL.USER_LOAD_URL, data)
export const userLogoutApi = (data) => post(URL.USER_LOGOUT_URL, data)
export const userProfileUpdateApi = (data) => patch(URL.USER_PROFILE_UPDATE_URL, data)
export const sponsorVendorProfileUpdateApi = (data) =>
    patch(URL.SPONSOR_VENDOR_PROFILE_UPDATE_URL, data)
export const userChangePasswordApi = (data) => patch(URL.USER_PASSWORD_CHANGE_URL, data)
export const userForgotPasswordApi = (data) => post(URL.USER_FORGOT_PASSWORD_URL, data)
export const clubRegisterApi = (data) => post(URL.CLUB_REGISTER_URL, data)
export const UserRegisterApi = (data) => post(URL.USER_REGISTER_URL, data)
export const verifyTokenApi = (data) => get(URL.CLUB_VERIFY_EMAIL_URL, data)
export const emailAlreadyExitApi = (data) => get(URL.CLUB_EMAIL_ALREADY_EXISTS_URL, data)
export const MemberEmailAlreadyExitApi = (data) => get(URL.MEMBER_EMAIL_ALREADY_EXISTS_URL, data)
export const userEmailResentApi = (data) => post(URL.CLUB_RESEND_VERIFY_EMAIL_URL, data)
export const memberEmailResentApi = (data) => post(URL.MEMBER_RESEND_VERIFY_EMAIL_URL, data)
export const memberVerifyTokenApi = (data) => get(URL.MEMBER_VERIFY_EMAIL_URL, data)
export const memberLogoutApi = (data) => post(URL.MEMBER_LOGOUT, data)
export const memberForgotPasswordApi = (data) => post(URL.MEMBER_FORGOT_PASSWORD_URL, data)
export const clubForgotPasswordApi = (data) => post(URL.CLUB_FORGOT_PASSWORD_URL, data)
