import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    ClubIDConfigurationApi,
    ClubPaymentIntentApi,
    ClubPaymentSuccessApi,
    MembershipAllStatusApi
} from './ClubShoppingCart.services'

//services

export const ClubPaymentIntent = createAsyncThunk(
    '/Club/payment/intent',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubPaymentIntentApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const ClubPaymentCompleted = createAsyncThunk(
    '/Club/payment/intent',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubPaymentSuccessApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipAllStatus = createAsyncThunk(
    '/membership/status',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await MembershipAllStatusApi(ID)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const ClubIDConfiguration = createAsyncThunk(
    '/club/id/configuration',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ClubIDConfigurationApi(ID)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
