import { createSlice } from '@reduxjs/toolkit'
import { PurchasedMemberShipList } from './thunk'

export const initialState = {
    list: [],
    loading: true
}
const PurchasedMembership = createSlice({
    name: 'PurchasedMemberShipList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(PurchasedMemberShipList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(PurchasedMemberShipList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data
            state.loading = false
        })
        builder.addCase(PurchasedMemberShipList.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default PurchasedMembership.reducer
