import { createSlice } from '@reduxjs/toolkit'
import { ClubPlansList, PlansDetail } from './thunk'

export const membershipForm = {
    club_id: '',
    user_id: '',
    club_membership_plans_id: '',
    total_number_of_memberbship: '',
    additional_char_position: null,
    amount: '',
    membership_data: []
}

export const initialState = {
    ClubPlanList: [],
    loading: true,
    planDetail: {},
    planDetailLoading: false,
    activePlan: '',
    form: membershipForm,
    page: 1,
    // limit: process.env.REACT_APP_PAGE_LIMIT || 10,
    limit: 10,
    totalPage: 1,
    totalRecords: 1
}
const ClubPlan = createSlice({
    name: '@ClubPlan',
    initialState,
    reducers: {
        paginationData: (state, action) => {
            const { payload } = action
            state.page = payload?.page || 1
            state.totalPage = payload?.totalPage || 1
            state.totalRecords = payload?.totalRecords || 1
            state.loading = false
        },
        nextPrevPage: (state, action) => {
            const { payload } = action
            state.page = payload || 1
        },
        changeActivePlan: (state, action) => {
            state.activePlan =
                state.activePlan === action.payload.activePlan ? '' : action.payload.activePlan
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubPlansList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubPlansList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.ClubPlanList = data
            state.loading = false
        })
        builder.addCase(ClubPlansList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(PlansDetail.pending, (state) => {
            state.planDetailLoading = true
        })
        builder.addCase(PlansDetail.fulfilled, (state, action) => {
            const { data } = action.payload
            state.planDetail = data
            state.planDetailLoading = false
        })
        builder.addCase(PlansDetail.rejected, (state) => {
            state.planDetailLoading = false
        })
    }
})

export const { paginationData, nextPrevPage, changeActivePlan } = ClubPlan.actions
export default ClubPlan.reducer
