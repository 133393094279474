import { createSlice } from '@reduxjs/toolkit'
import { stateList } from './thunk'

export const initialState = {
    StateList: [],
    loading: true
}

const CountryReducer = createSlice({
    name: 'Sponsor/Vendor',
    initialState,
    reducers: {
        resetState: (state) => {
            state.StateList = []
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(stateList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(stateList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.StateList = data
            state.loading = false
        })
        builder.addCase(stateList.rejected, (state) => {
            state.loading = false
        })
    }
})
export const { resetState } = CountryReducer.actions
export default CountryReducer.reducer
