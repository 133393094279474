import { createAsyncThunk } from '@reduxjs/toolkit'
import { ClubPlanListApi, PlansDetailApi } from './ClubPlans.services'
import { paginationData } from './reducer'

//services
export const ClubPlansList = createAsyncThunk(
    'Club/plan/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClubPlanListApi(data.ID, data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const PlansDetail = createAsyncThunk('/Club/plan/detail', async (data, { rejectWithValue }) => {
    try {
        const res = await PlansDetailApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
