import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClubPaymentIntentApi = (data) => API.post(`${URL.CLUB_PAYMENT_INTENT_CREATE}`, data)
export const ClubPaymentSuccessApi = (data) => API.post(`${URL.CLUB_PAYMENT_SUCCESS}`, data)

export const MembershipAllStatusApi = (ID) => API.get(`${URL.MEMBERSHIP_ALL_STATUS}/${ID}`)
export const ClubIDConfigurationApi = (ID) => API.get(`${URL.CLUB_ID_CONFIGURATION}/${ID}`)
