import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    showModal: false,
    modalContact: ''
}

const Modal = createSlice({
    name: 'Modal',
    initialState,
    reducers: {
        ModalAction: (state, action) => {
            const { showModal, modalContact } = action.payload
            state.showModal = showModal
            state.modalContact = modalContact
        }
    },
    extraReducers: () => {}
})

export const { ModalAction } = Modal.actions
export default Modal.reducer
