import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const PurchasedSanctionListApi = (data, params) =>
    API.post(
        `${URL.CLUB_PURCHASED_SANCTION_LIST}/?_sort=${params._sort}&_page=${params._page}&_limit=${params._limit}&_order=${params._order}`,
        data
    )
