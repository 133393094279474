import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SubUserListApi = (data) => API.get(`${URL.SUB_USER_LIST}`, data)
export const MemberUserListApi = (data) => API.get(`${URL.MEMBER_USER_LIST}`, data)
export const SubUserCreateApi = (data) => API.post(`${URL.SUB_USER_CREATE}`, data)
export const SubUserByIdApi = (Id) => API.get(`${URL.SUB_USER_ONE}/${Id}`)
export const SubUserUpdateApi = (Id, data) => API.patch(`${URL.SUB_USER_UPDATE}/${Id}`, data)
export const SubUserDeleteApi = (Id) => API.del(`${URL.SUB_USER_DELETE}/${Id}`)
