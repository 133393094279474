import { createAsyncThunk } from '@reduxjs/toolkit'
import { ClubSanctionPlanListApi, PlanSanctionDetailApi } from './ClubSanctionPlans.services'
import { paginationData } from './reducer'

//services
export const ClubSanctionPlansList = createAsyncThunk(
    'Club/sanction/plan/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClubSanctionPlanListApi(data.ID, data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const PlanSanctionDetail = createAsyncThunk(
    '/Club/sanction/plan/detail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await PlanSanctionDetailApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
