import { createSlice } from '@reduxjs/toolkit'
import { ClubMembershipList } from './thunk'

export const initialState = {
    ClubMemberShip: [],
    activeClub: '',
    loading: true
}
const ClubMembership = createSlice({
    name: '@ClubMembership',
    initialState,
    reducers: {
        changeActiveClub: (state, action) => {
            state.activeClub =
                state.activeClub === action.payload.activeClub ? '' : action.payload.activeClub
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubMembershipList.pending, (state) => {
            state.activeClub = ''
            state.loading = true
        })
        builder.addCase(ClubMembershipList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.ClubMemberShip = data
            state.loading = false
        })
        builder.addCase(ClubMembershipList.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { changeActiveClub } = ClubMembership.actions
export default ClubMembership.reducer
