const index = () => (
    <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        {' '}
        <h2>Only one tab is allowed at a time</h2>
    </div>
)

index.propTypes = {}

export default index
