import { createSlice } from '@reduxjs/toolkit'
import { ClubSanctionPaymentIntent } from './thunk'

export const initialState = {
    shoppingCart: {
        hasPayment: false
    },
    planDetailForm: {
        hasPayment: false
    },
    paymentData: {},
    loading: false
}
const ClubSanctionShoppingCart = createSlice({
    name: 'ClubSanctionShoppingCart',
    initialState,
    reducers: {
        addToShoppingCart: (state, action) => {
            const { payload } = action
            state.shoppingCart = payload
            state.paymentData = {}
            state.loading = false
        },
        addToPlanDetail: (state, action) => {
            const { payload } = action
            state.planDetailForm = payload
            state.paymentData = {}
            state.loading = false
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubSanctionPaymentIntent.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubSanctionPaymentIntent.fulfilled, (state, action) => {
            state.paymentData = action.payload
            state.loading = false
        })
        builder.addCase(ClubSanctionPaymentIntent.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { addToShoppingCart, addToPlanDetail } = ClubSanctionShoppingCart.actions
export default ClubSanctionShoppingCart.reducer
