import styled from 'styled-components'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { Container } from 'reactstrap'
import { Toast } from 'src/Atoms'
import ScrollToTop from 'src/Hooks/ScrollToTop/ScrollToTop'

const TheLayout = (props) => {
    return (
        <div id='layout-wrapper'>
            <ScrollToTop />
            <Header />
            <Toast />
            <Container fluid>
                <Main>{props.children}</Main>
            </Container>
            <Footer />
        </div>
    )
}

export default TheLayout

const Main = styled.main`
    margin-top: 6rem;
`
