import { createAsyncThunk } from '@reduxjs/toolkit'
import { PurchasedSanctionListApi } from './PurchasedMembership.services'
import { paginationData } from '../Pagination/reducers'

//services
export const PurchasedSanctionList = createAsyncThunk(
    'Clubs/Purchase/Sanction',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await PurchasedSanctionListApi(data.body, data.params)

            if (data.params._limit) {
                // Pagination Section
                const limit = data.params._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data.params._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
