import { createSlice } from '@reduxjs/toolkit'
import { countryList } from './thunk'

export const initialState = {
    CountryList: [],
    loading: true
}

const CountryReducer = createSlice({
    name: 'Sponsor/Vendor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(countryList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(countryList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.CountryList = data
            state.loading = false
        })
        builder.addCase(countryList.rejected, (state) => {
            state.loading = false
        })
    }
})

export default CountryReducer.reducer
