import { createSlice } from '@reduxjs/toolkit'
import {
    AdminMemberProcessFeeList,
    AdminSanctionProcessFeeList,
    ClubMemberProcessFeeList,
    ClubSanctionProcessFeeList
} from './thunk'
import _ from 'lodash'
// import _ from 'lodash'

export const clubProcessingFee = {
    processing_percentage: ''
}
export const adminProcessingFee = []
export const adminSanctionProcessingFee = {
    processing_percentage: ''
}
export const clubSanctionProcessingFee = {
    processing_percentage: ''
}
export const initialState = {
    loading: true,
    clubProcessingFee,
    adminProcessingFee,
    adminSanctionProcessingFee,
    clubSanctionProcessingFee
}
const ProcessingFees = createSlice({
    name: '@Processing Fees',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubMemberProcessFeeList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubMemberProcessFeeList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    processing_percentage: formData?.processing_percentage || ''
                }
            }

            state.clubProcessingFee = formData ? formData : clubProcessingFee
            state.loading = false
        })
        builder.addCase(ClubMemberProcessFeeList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSanctionProcessFeeList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubSanctionProcessFeeList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    processing_percentage: formData?.processing_percentage || ''
                }
            }

            state.clubSanctionProcessingFee = formData ? formData : clubProcessingFee
            state.loading = false
        })
        builder.addCase(ClubSanctionProcessFeeList.rejected, (state) => {
            state.loading = false
        })
        // Listing
        builder.addCase(AdminMemberProcessFeeList.pending, (state) => {
            state.loading = false
        })
        builder.addCase(AdminMemberProcessFeeList.fulfilled, (state, action) => {
            const { data } = action.payload

            // let formData = _.first(data)
            // if (formData) {
            //     formData = {
            //         processing_percentage: formData?.processing_percentage || ''
            //     }
            // }

            state.adminProcessingFee = data || []
            state.loading = false
        })
        builder.addCase(AdminMemberProcessFeeList.rejected, (state) => {
            state.loading = false
        })
        // Listing
        builder.addCase(AdminSanctionProcessFeeList.pending, (state) => {
            state.loading = false
        })
        builder.addCase(AdminSanctionProcessFeeList.fulfilled, (state, action) => {
            const { data } = action.payload
            // let formData = _.first(data)
            // if (formData) {
            //     formData = {
            //         adminSanctionProcessingFee: formData?.processing_percentage || ''
            //     }
            // }

            state.adminProcessingFee = data || []
            state.loading = false
        })
        builder.addCase(AdminSanctionProcessFeeList.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = ProcessingFees.actions
export default ProcessingFees.reducer
