import { createSlice } from '@reduxjs/toolkit'
import { ClubSanctionPlansList, PlanSanctionDetail } from './thunk'

export const sanctionForm = {
    club_id: '',
    sanction_plan_id: ''
}

export const initialState = {
    ClubSanctionPlanList: [],
    loading: true,
    planDetail: {},
    planDetailLoading: false,
    form: sanctionForm,
    page: 1,
    limit: 10,
    totalPage: 1,
    totalRecords: 1
}
const ClubSanctionPlan = createSlice({
    name: '@ClubSanctionPlan',
    initialState,
    reducers: {
        paginationData: (state, action) => {
            const { payload } = action
            state.page = payload?.page || 1
            state.totalPage = payload?.totalPage || 1
            state.totalRecords = payload?.totalRecords || 1
            state.loading = false
        },
        nextPrevPage: (state, action) => {
            const { payload } = action
            state.page = payload || 1
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubSanctionPlansList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubSanctionPlansList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = sanctionForm
            state.ClubSanctionPlanList = data
            state.loading = false
        })
        builder.addCase(ClubSanctionPlansList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(PlanSanctionDetail.pending, (state) => {
            state.planDetailLoading = true
        })
        builder.addCase(PlanSanctionDetail.fulfilled, (state, action) => {
            const { data } = action.payload
            state.planDetail = data
            state.planDetailLoading = false
        })
        builder.addCase(PlanSanctionDetail.rejected, (state) => {
            state.planDetailLoading = false
        })
    }
})

export const { paginationData, nextPrevPage, changeActivePlan } = ClubSanctionPlan.actions
export default ClubSanctionPlan.reducer
