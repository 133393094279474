import { createSlice } from '@reduxjs/toolkit'
import { MemberUserList, SubUserById, SubUserCreate, SubUserList } from './thunk'
import _ from 'lodash'
import { getDateWithFormat } from 'src/helpers/dateTimeHelper'

export const SubUserForm = {
    first_name: '',
    last_name: '',
    is_minor: false,
    active: true,
    date_of_birth: '',
    arba_number: ''
}

export const initialState = {
    form: SubUserForm,
    list: [],
    allUserList: [],
    loading: true
}

const SubUserReducer = createSlice({
    name: '/SubUser/',
    initialState,
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SubUserList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SubUserList.fulfilled, (state, action) => {
            const { data } = action.payload
            const sortData = Array.isArray(data)
                ? _.map(data, (q) => {
                      return { ...q, full_name: `${q.last_name} ${q.first_name}` }
                  })
                : []

            state.form = SubUserForm
            state.list = sortData
            state.loading = false
        })
        builder.addCase(SubUserList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(MemberUserList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberUserList.fulfilled, (state, action) => {
            const { data } = action.payload
            const sortData = Array.isArray(data)
                ? _.map(data, (q) => {
                      return { ...q, label: `${q.last_name} ${q.first_name}`, value: q.id }
                  })
                : []
            state.allUserList = sortData
            state.loading = false
        })
        builder.addCase(MemberUserList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SubUserCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SubUserCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(SubUserCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SubUserById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SubUserById.fulfilled, (state, action) => {
            const { payload } = action
            const data = _.pick(payload?.data, Object.keys(SubUserForm))
            const obj = {
                ...data,
                is_minor: data?.is_minor === 'Y' ? true : false,
                date_of_birth: data?.date_of_birth ? new Date(getDateWithFormat(data?.date_of_birth)) : ''
            }
            state.form = obj
            state.loading = false
        })
        builder.addCase(SubUserById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {  } = SubUserReducer.actions
export default SubUserReducer.reducer
