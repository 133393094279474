import { Suspense, useEffect } from 'react'
import { Loader } from './Atoms'
import './assets/scss/theme.scss'
import TheLayout from 'src/components/TheLayout/TheLayout'
import AuthGuard from './routes/middleware/AuthGuard'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { PublicRoute, userRoutes } from './routes/allRoutes'
import { withOneTabEnforcer } from 'react-one-tab-enforcer'
import DifferentWarningComponent from './pages/DifferentWarningComponent'
import { useDispatch, useSelector } from 'react-redux'
import { userLoad } from './store/Auth/thunk'

const App = () => {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const { loading, user } = useSelector((state) => state.Login)

    useEffect(() => {
        dispatch(userLoad())
    }, [])

    const getlogout = searchParams.get('logout')

    let userData = localStorage.getItem('user')
    if (getlogout === 'true') {
        localStorage.clear()
    } else if (userData) {
        const accessToken = localStorage.getItem('accessToken')
        const refreshToken = localStorage.getItem('refreshToken')
        userData = JSON.parse(userData)
        if (userData.role === 'club') {
            window.location.replace(
                `${
                    process.env.REACT_APP_CLUB_URL
                }/token?accessToken=${accessToken}&refreshToken=${refreshToken}&userData=${JSON.stringify(
                    userData
                )}`
            )
        }
    }
    if (loading) {
        return (
            <div
                style={{ height: '100vh' }}
                className='d-flex  justify-content-center align-items-center'
            >
                <Loader color='primary' />
            </div>
        )
    }

    return (
        <Routes>
            {!loading && user.user_role === 'user'
                ? userRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <TheLayout>
                                      <Suspense
                                          fallback={
                                              <div
                                                  style={{ height: '100vh' }}
                                                  className='d-flex  justify-content-center align-items-center'
                                              >
                                                  <Loader color='primary' />
                                              </div>
                                          }
                                      >
                                          {route.component}
                                      </Suspense>
                                  </TheLayout>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}
            {PublicRoute.map((route, index) => (
                <Route
                    path={route.path}
                    element={
                        <TheLayout>
                            <Suspense
                                fallback={
                                    <div
                                        style={{ height: '100vh' }}
                                        className='d-flex  justify-content-center align-items-center'
                                    >
                                        <Loader color='primary' />
                                    </div>
                                }
                            >
                                {route.component}
                            </Suspense>
                        </TheLayout>
                    }
                    key={index}
                    isAuthProtected={false}
                />
            ))}
        </Routes>
    )
}

App.propTypes = {}
export default withOneTabEnforcer({
    appName: 'Front-end-Panel-Club-software',
    localStorageResetInterval: 10 * 1000,
    localStorageTimeout: 10 * 1000,
    OnlyOneTabComponent: DifferentWarningComponent
})(App)
