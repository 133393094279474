import { createSlice } from '@reduxjs/toolkit'
import {
    sponsorVendorProfileUpdate,
    userChangePassword,
    userLoad,
    userLogin,
    userLogout,
    userProfileUpdate,
    verifyToken
} from './thunk'
import { toast } from 'react-toastify'
import _ from 'lodash'

export const loginForm = {
    email: '',
    password: '',
    role: 'user_member'
}

export const clerkLoginForm = {
    username: '',
    role: 'club_clerk'
}

export const clubRegister = {
    club_name: '',
    email: '',
    password: '',
    c_password: '',
    term: false
}
export const userRegister = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    c_password: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: '',
    user_address_id: '',
    country_id: '',
    zipcode: '',
    phone: '',
    arba_number: '',
    term: false,
    is_minor: false,
    date_of_birth: ''
}

export const initialState = {
    token: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    loginForm,
    clubRegisterForm: clubRegister,
    userRegisterForm: userRegister,
    clerkLoginForm,
    user: {},
    loading: true,
    isAuth: false
}

const loginSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        userRoleChange: (state, action) => {
            const { payload } = action
            state.loginForm.role = payload.role
        },
        AuthFail: (state) => {
            localStorage.clear()
            state.loading = false
            state.clubRegisterForm = userRegister
            state.userRegisterForm = userRegister
            state.loginForm = loginForm
            state.token = null
            state.refreshToken = null
            state.user = {}
            state.loading = false
            state.isAuth = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.pending, (state) => {
            state.loading = false
        })
        builder.addCase(userLogin.fulfilled, (state, action) => {
            const { accessToken, refreshToken, data } = action.payload
            toast.success('Login success')
            if (data?.role === 'club') {
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken)
                localStorage.setItem('user', JSON.stringify(data))
            } else {
                localStorage.setItem('userAccessToken', accessToken)
                localStorage.setItem('userRefreshToken', refreshToken)
                localStorage.setItem('userData', JSON.stringify(data))
            }
            setTimeout(() => {
                if (['club', 'club_clerk'].includes(data?.role)) {
                    window.location.replace(
                        `${
                            process.env.REACT_APP_CLUB_URL
                        }/token?accessToken=${accessToken}&refreshToken=${refreshToken}&userData=${JSON.stringify(
                            data
                        )}`
                    )
                }
            }, 1000)

            state.token = accessToken
            state.refreshToken = refreshToken
            state.user = data
            state.loading = false
            state.isAuth = true
        })
        builder.addCase(userLogin.rejected, (state) => {
            state.token = null
            state.refreshToken = null
            state.user = {}
            state.loading = false
            state.isAuth = false
        })

        builder.addCase(userLoad.pending, (state) => {
            state.loading = true
        })
        builder.addCase(userLoad.fulfilled, (state, action) => {
            const { data } = action.payload

            const regForm = {
                ...data,
                ..._.first(data.address),
                is_minor: data.is_minor === 'Y' ? true : false,
                date_of_birth: data?.date_of_birth
            }
            state.user = data
            state.userRegisterForm = _.pick(regForm, Object.keys(userRegister))
            state.loading = false
            state.isAuth = true
        })
        builder.addCase(userLoad.rejected, (state) => {
            localStorage.removeItem('userAccessToken')
            localStorage.removeItem('userRefreshToken')
            localStorage.removeItem('userData')
            state.userRegisterForm = userRegister
            state.token = null
            state.refreshToken = null
            state.user = {}
            state.loading = false
            state.isAuth = false
        })
        // Logout
        builder.addCase(userLogout.pending, (state) => {
            state.loading = false
        })
        builder.addCase(userLogout.fulfilled, (state) => {
            localStorage.clear()
            state.user = {}
            state.token = null
            state.token = null
            state.loading = false
            state.isAuth = false
            state.userRegisterForm = userRegister
        })

        builder.addCase(userLogout.rejected, (state) => {
            state.isAuth = true
            state.loading = false
        })
        builder.addCase(userProfileUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(userProfileUpdate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(userProfileUpdate.rejected, (state) => {
            state.loading = false
        })
        // Sponsor Vendor Profile Update
        builder.addCase(sponsorVendorProfileUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(sponsorVendorProfileUpdate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(sponsorVendorProfileUpdate.rejected, (state) => {
            state.loading = false
        })

        // Changes Password

        builder.addCase(userChangePassword.pending, (state) => {
            state.loading = false
        })
        builder.addCase(userChangePassword.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(userChangePassword.rejected, (state) => {
            state.loading = false
        })
        // Changes Password

        builder.addCase(verifyToken.pending, (state) => {
            state.loading = false
        })
        builder.addCase(verifyToken.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(verifyToken.rejected, (state) => {
            state.loading = false
        })

        // Club Register
    }
})

export const { AuthFail, userRoleChange } = loginSlice.actions
export default loginSlice.reducer
